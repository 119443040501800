@font-face {
  font-family: "icons";
  src: url('/fonts/icons.eot');
  src: url('/fonts/icons.eot?#iefix') format('eot'),
    url('/fonts/icons.woff2') format('woff2'),
    url('/fonts/icons.woff') format('woff'),
    url('/fonts/icons.ttf') format('truetype'),
    url('/fonts/icons.svg#icons') format('svg');
}

@mixin icon-styles {
  font-family: "icons";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
  text-decoration: none;
  text-transform: none;
}

%icon {
  @include icon-styles;
}

@function icon-char($filename) {
  $char: "";

  @if $filename == cheese {
    $char: "\E001";
  }
  @if $filename == chicken {
    $char: "\E002";
  }
  @if $filename == eggs {
    $char: "\E003";
  }
  @if $filename == fish {
    $char: "\E004";
  }
  @if $filename == hamburger {
    $char: "\E005";
  }
  @if $filename == meat {
    $char: "\E006";
  }
  @if $filename == sausage {
    $char: "\E007";
  }
  @if $filename == vegetables {
    $char: "\E008";
  }

  @return $char;
}

@mixin icon($filename, $insert: before, $extend: true) {
  &:#{$insert} {
    @if $extend {
      @extend %icon;
    } @else {
      @include icon-styles;
    }
    content: icon-char($filename);
  }
}

.icon-cheese {
  @include icon(cheese);
}
.icon-chicken {
  @include icon(chicken);
}
.icon-eggs {
  @include icon(eggs);
}
.icon-fish {
  @include icon(fish);
}
.icon-hamburger {
  @include icon(hamburger);
}
.icon-meat {
  @include icon(meat);
}
.icon-sausage {
  @include icon(sausage);
}
.icon-vegetables {
  @include icon(vegetables);
}
