.panels{
  .panels-navigation{
    background: $maincolor;
    color: #fff;
    .MuiButtonBase-root{
      opacity: 0.6;
      color: #fff;
      transition: 0.3s;
      &.Mui-selected{
        opacity: 1;
        transform: scale(1.4);
      }
    }
  }
}

.panel-contents-footer{
  border-radius: 50%; // car ne contient qu'un fab
  .MuiFab-root{
    margin: 0 auto !important;
  }
}

.panel-active{
  .panel-contents-footer{
    width: auto !important;
    text-align: center;
    .MuiFab-root{
      margin-left: auto !important;
      margin-right: auto !important;
      + .MuiFab-root{
        margin-left: 2rem !important;
      }
    }
  }
}