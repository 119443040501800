.itemAsCard{
  border-radius: 10px;
  background: #efefef;
  position: relative;
  font-size: 0.8rem;

  + .itemAsCard{
    margin-top: 0.5rem;
  }

  .chip{
    border-radius: 10px;
    padding: 0.2rem 0.5rem;
    background: #e0e0e0;
    position: relative;
    overflow: hidden;

    .action{
      // margin-top: 0.2em;
    }

    // icon de fond "decongele"
    .isDecongeled{
      color: #009dff; // bleu
      font-size: 3rem;
      line-height: 1;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-40px,-10px);
      white-space: nowrap;
      opacity: 0.4;
      width: 113px;
      svg{
        vertical-align: middle;
        font-size: 3rem;
        line-height: 1;
      }
      span{
        display: inline-block;
        vertical-align: middle;
        font-size: 1rem;
        line-height: 1;
        font-weight: bold;
      }
    }
    // icon de fond "congele"
    .isCongeled{
      color: #009dff; // bleu
      font-size: 3rem;
      line-height: 1;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-40px,-10px);
      white-space: nowrap;
      opacity: 0.2;
      width: 113px;
      svg{
        vertical-align: middle;
        font-size: 3rem;
        line-height: 1;
      }
      span{
        display: inline-block;
        vertical-align: middle;
        font-size: 1rem;
        line-height: 1;
        font-weight: bold;
      }
    }
    .isConserve{
      color: #999; // gris
      font-size: 3rem;
      font-style: italic;
      line-height: 1;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-40px,-10px);
      white-space: nowrap;
      opacity: 0.4;
      width: 113px;
      svg{
        vertical-align: middle;
        font-size: 2.8rem;
        line-height: 1;
      }
      span{
        display: inline-block;
        vertical-align: middle;
        font-size: 1rem;
        line-height: 1;
        font-weight: bold;
      }
    }
  }
  .under{
    padding: 0.2rem 0.5rem;
  }

  .barcode{
    color: #999;
    font-size: 0.7rem;
    svg{
      width: 0.6em;
      height: 0.6em;
      font-size: 1.5em;
    }
  }
  .name{
    display: inline-block;
    vertical-align: middle;
    font-weight: bold;
  }
  .delay{
    font-size: 0.8em;
  }

  .previsional{
    position: absolute;
    top: -0.5rem;
    left: 0.5rem;
    font-size: 0.7rem;
  }

  small svg{
    width: 1em;
    height: 1em;
    font-size: 1em;
  }

  dd{
    margin-inline-start: 2.4rem;
  }

  .unknown{
    color: lightgrey;
    font-style: italic;
    font-size: smaller;
  }


  &.purple{
    border: 2px solid thistle;
  }
  &.green{
    border: 2px solid lightgreen;
  }
  &.orange{
    border: 2px solid #fcd26a;
  }
  &.red{
    border: 2px solid #f94f69;
  }
  &.grey{
    border: 2px solid black;
  }
  &.blue{
    border: 2px solid paleturquoise;
  }
}