*{
  box-sizing: border-box;
}

.float-left{
  float: left;
}
.float-right{
  float: right;
}
.reset-float{
  &:after{
    content: '';
    display: block;
    clear: both;
  }
}
.absolute-bottom-right{
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}
.absolute-top-right{
  position: absolute;
  top: 1rem;
  right: 1rem;
  .MuiFab-root + .MuiFab-root{
    margin-left: 0.5rem;
  }
}
.absolute-top-left{
  position: absolute;
  top: 1rem;
  left: 1rem;
  .MuiFab-root + .MuiFab-root{
    margin-left: 1rem;
  }
}
.fixed-bottom-center{
  position: fixed;
  bottom: 1rem;
  left: 50%;  // attention depend du panel
  transform: translateX(-50%);
}

.text-right{
  text-align: right;
}
.text-left{
  text-align: left;
}
.text-center{
  text-align: center;
}

h1,h2,h3,h4{
  margin: 0;
  line-height: 1;
}

h2{
  font-size: 1.1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  .MuiCardHeader-title &{
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
}
h3{
  font-size: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

dl{
  padding: 0;
  margin: 0;
}

hr{
  width: 100%;
}

.clickable{
  &:hover{
    cursor: pointer;
    background: #EFEFEF;
  }
}


.m-1{
  margin: 1rem;
}
.m-v-05{
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.m-v-1{
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.m-v-2{
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.m-b-05{
  margin-bottom: 0.5rem;
}
.m-b-1{
  margin-bottom: 1rem;
}
.m-b-2{
  margin-bottom: 2rem;
}
.p-v-05{
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.p-v-1{
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pr-1{
  padding-right: 1rem;
}
.p-1{
  padding: 1rem;
}
.mv-1{
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.mb-1{
  margin-bottom: 1rem;
}
.mv-2{
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.mb-2{
  margin-bottom: 2rem;
}
.mh-2{
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}
.pt-0{
  padding-top: 0 !important;
}
.pb-0{
  padding-bottom: 0 !important;
}
.pt-05{
  padding-top: 0.5rem !important;
}
.pt-1{
  padding-top: 1rem !important;
}
.pb-6{
  padding-bottom: 6rem !important;
}

.spacer{
  display: inline-block;
  width: 1rem;
}

.clear{
  &:after{
    content: '';
    display: block;
    clear: both;
  }
}

.w100{
  width: 100%;
}

.combinedIcons{
  position: relative;
  display: inline-block;
  > svg:nth-child(2){
    position: absolute;
    top: -0.2rem;
    right: -0.2rem;
    font-size: 1rem;
  }
}

.crossed{
  position: relative;
  display: inline-block;
  &:after{
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background: #000;
    transform: rotate(-45deg);
    transform-origin: center center;
    position: absolute;
    top: 50%;
  }
}
.freezeIcon{
  color: #009dff; // bleu
  &:after{
    background: #666;
  }
}

.icon-prefix{
  min-width: 2.4rem;
  display: inline-block;
  vertical-align: top;
  svg{
    font-size: 1rem;
    vertical-align: top !important;
  }
}



// semainier
.dateInText{
  text-transform: uppercase;
}
.dateInNumbers{
  font-size: 0.7rem;
}

.overflow-x{
  max-width: 100%;
  overflow-x: auto;
}