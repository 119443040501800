.modelCard{
  padding: 5px;

  .modelCardCell{
    box-shadow: 0 0 2px rgba(0,0,0,0.2);
    // text-align: center;
    padding: 5px 10px;
    border-radius: 3px;
    background: #EFEFEF;
    position: relative;
    font-size: 0.8em;

    .star{
      position: absolute;
      top: 0;
      left: 80%;
      transform: translate(-50%,0);
      color: #FFF;
      opacity: 0.5;
      svg{
        font-size: 3.7rem;
      }
    }
    .content{
      position: relative;
    }

    .unknown{
      color: lightgrey;
      font-style: italic;
    }
    
  }
}