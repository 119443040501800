
// couleurs titres
h2{
  text-transform: uppercase;
  margin: 0 auto;
  font-size: 1rem;
  > svg{
    vertical-align: middle;
  }
  &.success{
    color: rgb(46, 125, 50);
  }
  &.danger{
    color: rgb(211, 47, 47);
  }
}
h3{
  color: $maincolor;
}

// reduire la font des tabs de Panel
// .MuiTab-wrapper{
//   font-size: 0.6rem;
// }

// skin Panels avec vague
// .panels .panels-navigation{
//   background: $maincolor;
//   color: #fff;

//   position: relative;
//   > svg{
//     transform: translateX(-80px) scaleY(0.5);
//     transform-origin: top;
//     position: absolute;
//     top: 90%;
//     width: calc( 100% + 80px );
//     z-index: 1;
//     transition: 0.5s;
//   }
// }
// .panel-active-0{
//   .panels-navigation > svg{
//     left: 80px;
//     transition: 0.5s;
//   }
// }
// .panel-active-1{
//   .panels-navigation > svg{
//     left: 60px;
//     transition: 0.5s;
//   }
// }
// .panel-active-2{
//   .panels-navigation > svg{
//     left: 40px;
//     transition: 0.5s;
//   }
// }
// .panel-active-3{
//   .panels-navigation> svg{
//     left: 20px;
//     transition: 0.5s;
//   }
// }
// .panel-active-5{
//   .panels-navigation> svg{
//     left: 0px;
//     transition: 0.5s;
//   }
// }
.mt-vague{
  margin-top: 12vw !important;
}





// elements main color
.MuiFab-primary{
  background-color: $maincolor !important;
}
.MuiButton-textPrimary,
.primary{
  color: $maincolor !important;
}
// .panel-contents-footer{
//   border-radius: 50%; // car ne contient qu'un fab
//   .MuiFab-root{
//     margin: 0 auto !important;
//   }
// }
.MuiButton-outlinedPrimary{
  border-color: $maincolor !important;
  color: $maincolor !important;
}























.MuiButton-root.wide,
.MuiChip-root.wide{
  width: 100%;
}
.MuiChip-root.m-05-r{
  margin-right: 1rem;
  margin-bottom: 0.5rem;
}

.MuiCardHeader-root{
  // padding: 0 !important;
}

.MuiChip-label{
  padding-right: 2rem !important;
  svg:not(:first-child){
    vertical-align: middle;
    margin-left: 0.5rem;
    font-size: 1rem;
    line-height: 1;
  }
  svg:first-child{
    vertical-align: middle;
    font-size: 1rem;
    line-height: 1;
  }
  .crossed{
    margin-left: 0.5rem;
    svg{
      margin-left: 0;
    }
  }
  small svg{
    margin-left: 0;
  }
  .previsional{
    position: absolute;
    top: -0.5rem;
    left: 2.4rem;
    font-size: 0.7rem;
  }
} 
.MuiChip-root,
.MuiCard-root{
  &.purple{
    border: 2px solid thistle;
  }
  &.green{
    border: 2px solid lightgreen;
  }
  &.orange{
    border: 2px solid #fcd26a;
  }
  &.red{
    border: 2px solid #f94f69;
  }
  &.grey{
    border: 2px solid black;
  }
  &.blue{
    border: 2px solid paleturquoise;
  }
}

.MuiCard-root{
  .MuiCard-root .MuiAvatar-root{
    background-color: #DFDFDF;
  }
  svg{
    vertical-align: middle;
  }
  .delay{
    margin-left: 0.5rem;
    font-style: italic;
    display: inline-block;
    vertical-align: middle;
  }
}
.MuiChip-root{
  // height: 40px !important;
  .MuiAvatar-root{
    background-color: #EFEFEF;
  }
  .delay{
    margin-left: 0.5rem;
    font-style: italic;
  }
}

.MuiCardContent-root.no-p-t{
  padding-top: 0 !important;
}

.MuiTableCell-sizeSmall {
  padding: 6px 6px !important;
}

.MuiButtonGroup-root{
  vertical-align: middle;
  + .MuiButtonGroup-root{
    margin-left: 1rem;
  }
}

h2 .MuiAvatar-root{
  display: inline-flex;
  vertical-align: middle;
}


.MuiButtonGroup-grouped.empty{
  min-width: 10px;
}

// @media (min-width: 0px){  // xs
//   .MuiGrid-grid-xs-6:nth-child(2n){
//     padding-left: 0.5rem;
//   }
//   .MuiGrid-grid-xs-6:nth-child(2n+1){
//     padding-right: 0.5rem;
//   }
// }
// @media (min-width: 960px){  // md
//   .MuiGrid-grid-md-6:nth-child(2n){
//     padding-right: 0.5rem;
//   }
//   .MuiGrid-grid-md-6:nth-child(2n+1){
//     padding-left: 0.5rem;
//   }
// }
// @media (min-width: 1280px){  // lg
//   .MuiGrid-grid-lg-4:nth-child(3n){
//     padding-right: 0.5rem;
//   }
//   .MuiGrid-grid-lg-4:nth-child(3n+1){
//     padding-left: 0.5rem;
//     padding-right: 0.5rem;
//   }
//   .MuiGrid-grid-lg-4:nth-child(3n+2){
//     padding-left: 0.5rem;
//   }
// }

// semainier
.MuiCard-root.day{
  &.past{
    background: #EFEFEF;
    font-style: italic;
    opacity: 0.6;
  }
  &.current{
    // background: #aafff3;
  }
  .MuiCardContent-root{
    @media (max-width: 959px){  // md
      padding-left: 8px;
      padding-right: 8px;
    }
  }
  .MuiCardContent-root:last-child{
    padding-bottom: 16px;
  }
  .flex{
    display: flex;
    align-items: center;
    .date{
      width: 80px;
      min-width: 80px;
    }
    .contents{
      flex: 1;
    }
  }
}
.table-semainier{
  table-layout: fixed;
  .MuiTableCell-root{
    border: 0;
  }
}





// barcode
.container {
  position: relative;
}
.container,
#interactive.viewport {
  width: 248px;
  height: 248px;
  margin: 0 auto;
}
#interactive.viewport canvas,
video {
  width: 248px;
  height: 248px;
  position: absolute;
  top: 0;
  left: 0;
  aspect-ratio: auto 300 / 300;
  margin: 0 auto;
}
#interactive.viewport canvas.drawingBuffer,
video.drawingBuffer {
  width: 248px;
  height: 248px;
  margin: 0 auto;
}



.smallcard{
  .MuiCardActions-root{
    .MuiIconButton-root{
      padding: 6px !important;
    }
  }
}





